import React, { useEffect } from "react" 
import { Link } from "@StarberryUtils";
import ValuationFormPage from "../forms/valuation-form";
import ModalTiny from "react-bootstrap/Modal"

// markup
const StickyFooter = (props) => { 

  const [modalValuationformOpen, setValuationformOpen] = React.useState(false);
  const openValuationformModal = () => {
    setValuationformOpen(true);
  }
  const closeValuationformModal = () => {
    setValuationformOpen(false);
  }
  useEffect(() => {

  }) 
  return (

    <React.Fragment>
      <div className="sticky-btn d-lg-none">
        <button className="btn btn-primary" onClick={openValuationformModal}>Valuation</button>
        <Link to={`/contact`} className="btn btn-dark">Contact</Link>
      </div>  
      
      <ModalTiny size="lg" show={modalValuationformOpen} onHide={closeValuationformModal} className="book-a-viewing-modal share-to-friend-modal">
        <ModalTiny.Header className="contact-close-btn ">
          <span class="close-menu" onClick={closeValuationformModal}><i class="close-icon"></i></span>
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0">Property Valuation</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section">
          <ValuationFormPage /></ModalTiny.Body>

      </ModalTiny>
    </React.Fragment>
  )
}

export default StickyFooter